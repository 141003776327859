import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/Peyote-logo-dark.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content">
          <div className="container">
            <div className="link">
              <span>Visit our partner agency at:</span>
              <Link to="https://cactusinc.com" target="_blank" rel="noreferrer">cactusinc.com</Link>
            </div>
            <div className="logo">
              <img
                src={logo}
                alt="Peyote Pictures"
              />
              <span>Copyright © Peyote Pictures 2021</span>
            </div>
          </div>
        </div>
        <br />
      </footer>
    )
  }
}

export default Footer
